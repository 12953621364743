import {AfterViewInit, Component, ElementRef, ViewChild, Inject, PLATFORM_ID, OnInit} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {ActivatedRoute} from "@angular/router";
import {Store} from "@ngrx/store";
import {
  AUTH_SIGN_IN_WITH_TELEGRAM,
  signInWithTelegram
} from "@revolve-app/app/core/features/auth/store/auth/auth.actions";
import {TSignInWithTelegramRequest} from "@revolve-app/app/core/metadata/types/auth.models";

@Component({
  selector: 'app-telegram-login',
  standalone: true,
  templateUrl: './telegram-login.component.html',
  styleUrl: './telegram-login.component.scss'
})
export class TelegramLoginComponent  implements OnInit, AfterViewInit{

  @ViewChild('script', { static: true }) script!: ElementRef;
  constructor(
    private route: ActivatedRoute,
    private storeService: Store,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if(params && params['provider']){
        this.storeService.dispatch(signInWithTelegram({signInInput: params as TSignInWithTelegramRequest,redirectUrl:'/customer'}))
      }
    });
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.convertToScript();
    }
  }
  convertToScript() {
    const element = this.script.nativeElement;
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?22';
    script.setAttribute('data-telegram-login', 'Kbot_local_bot');
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-userpic', 'false');
    script.setAttribute('data-radius', '8');
    script.setAttribute('data-auth-url', 'http://127.0.0.1:80/auth/signin?provider=telegram');
    script.setAttribute('data-request-access', 'write');
    element.parentElement.replaceChild(script, element);
  }
}
